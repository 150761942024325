//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "users/getField",
  mutationType: "users/updateField",
});
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "RecoverPage",
  data() {
    return {
      waiting: false,
      errorMessage: null,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    ...mapFields({
      email: "emailRecover",
    }),
    emailErrorMessage() {
      if (!this.$v.email.required) {
        return "Preencha seu email";
      } else if (!this.$v.email.email) {
        return "Por favor insira um email válido";
      }
      return "";
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        if (this.waiting) {
          this.$q.notify({
            message: "Por favor, aguarde.",
            position: 'top-right',
          });
          return;
        }
        this.waiting = true;
        this.$store
          .dispatch("users/sendEmailRecover")
          .then(() => {
            this.email = '';
            this.waiting = false;
          })
          .catch(() => {
            this.waiting = false;
          });
      } else {
        this.$q.notify({
          message: "Por favor, preencha o campo corretamente.",
          position: 'top-right',
        });
      }
    },
  },
};
